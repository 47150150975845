import "../../assets/css/styles.css";
import BkBanner from "../../assets/brand/bk-banner-2024.webp";
import {Link} from "react-router-dom";

const RegistrationError = () => {
  return (
    <div class="registration-error-root">
      <main className="registration-error-main">
        <section class="registration-error-header">
          <img src={BkBanner} alt="bk-banner" loading="eager" />
        </section>
        <section class="registration-error-content">
          <article className="article">
            <h1 className="h1">Access denied.</h1>
            <p className="p">If you believe this is an error, please contact <a href={"mailto:team@bizknowledge.com"}> team@bizknowledge.com </a> for assistance.</p>
          </article>
        </section>
        <section class="registration-error-footer">
          By participating you acknowledge that you agree to BizKnowledge's{" "}
          <a href="https://www.bizknowledge.com/privacy-policy">
            Privacy Policy
          </a>{" "}
          and{" "}
          <a href="https://www.bizknowledge.com/terms-of-service">
            Terms of Use
          </a>
        </section>
      </main>
    </div>
  );
};

export default RegistrationError;
